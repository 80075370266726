let sentryEnabled = false;
export async function initSentry() {
  const Sentry = await import('@sentry/react');
  const dsn = import.meta.env.VITE_SENTRY_DSN;
  if(dsn) {
    sentryEnabled = true;
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN
    });
  }
}

export async function setSentryUser(email: string) {
  if(sentryEnabled) {
    const Sentry = await import('@sentry/react');
    Sentry.setUser({ email: email });
  }
}

export function reportException(e: any) {
  void reportExceptionInner(e);
}

 async function reportExceptionInner(e: any) {
  console.error(e);
  const Sentry = await import('@sentry/react');
  Sentry.captureException(e);
}
